const styles = (theme) => ({
  root: {},
  formWrapper: {
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(5.5)}px ${theme.spacing(2)}px ${theme.spacing(6)}px`,
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(5)}px`,
      minWidth: 480,
      maxWidth: 480,
      margin: `${theme.spacing(6)}px auto`,
      border: '1px solid #E0E0E0',
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(5)}px`,
      minWidth: 480,
      maxWidth: 480,
      margin: `${theme.spacing(6)}px auto`,
      border: '1px solid #E0E0E0',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formSectionTitle: {
    fontSize: 16,
    lineHeight: '130%',
    color: '#282828',
    fontWeight: 800,
    paddingBottom: theme.spacing(1.5),
  },
  crLogoWrapper: {
    maxHeight: '2.375rem',
    [theme.breakpoints.up('xs')]: {
      textAlign: 'center',
    },
  },
  linkWrapper: {
    color: '#666666',
  },
  link: {
    textDecoration: 'underline',
    fontSize: 11,
    lineHeight: '13px',
  },
  termsPolicyLink: {
    textDecoration: 'underline',
    color: '#0050FF',
    padding: `0 ${theme.spacing(0.5)}px`,
  },
  helperText: {
    margin: `${theme.spacing(1)}px 0px`,
  },
  buttonWrapper: {
    paddingTop: theme.spacing(3),
    alignItems: 'flex-end',
  },
  readonlyField: {
    borderWidth: '1px',
    opacity: 0.5,
    borderColor: `${theme.default.palette.coolgrey}!important`,
  },
  checkbox: {
    padding: '0 0.5rem 0 0.5rem',
    '&&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focusVisible': {
      color: '#8700D7',
    },
  },
  errorField: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    margin: 0,
  },
  infoField: {
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  formFieldLink: {
    color: '#0050FF',
  },
})
export default styles
