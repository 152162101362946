import React, { Component } from 'react'
import Router from 'next/router'

class App extends Component<any> {
  constructor(props, context) {
    super(props, context)
  }

  componentDidMount() {
    const { pathname } = Router
    if (pathname == '/') {
      // Default redirection here
      // Router.push('/login')
    }
  }

  render() {
    if (this.props.authState == 'signedIn') {
      return (
        <div>
          <h1>Internal App</h1>
        </div>
      )
    } else {
      return null
    }
  }
}

export default App
