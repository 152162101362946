//core
import { useState } from 'react'
import Router from 'next/router'
import Head from 'next/head'

// 3rd party
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from 'next/link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { InputLabel, InputAdornment, IconButton, FormHelperText } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { object, string, boolean } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

// local
import { Header, CRButton } from 'components'
import { _encodedForm, _parseError } from 'core'
import useSettings from 'hooks/useSettings'
import styles from './styles'
import { contactUsUrl } from 'core/constants'

//model
import { theme } from 'styles'

type Inputs = {
  email: string
  first_name: string
  last_name: string
  password: string
  acceptTerms: boolean
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  link: {
    textDecoration: 'underline',
    fontSize: 11,
    lineHeight: '13px',
    zIndex: 10,
    color: (props: any) => props.color,
  },
}))

interface IError {
  code: string
  message: string
  name?: string
}

export default function SignUp() {
  const { settings } = useSettings()
  const { client_id, mobile, first_name, last_name, post_code, email, partner, redirect_uri } = settings

  const schema = object().shape({
    email: string().required('Email is required').default(email).email('Please enter a valid email address.'),
    first_name: string().required('First name is required').default(first_name),
    last_name: string().required('Last name is required').default(last_name),
    password: string().required('Password is required').min(8, 'Your password must be at least 8 characters.'),
    acceptTerms: boolean().oneOf([true], 'Please agree to the Terms & Conditions to proceed.'),
  })
  const { register, handleSubmit, errors } = useForm<Inputs>({ resolver: yupResolver(schema) })
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<IError>({
    code: '',
    message: '',
    name: '',
  })

  const inputProps: any = { inputRef: register, required: true }

  const classes = useStyles({
    color: partner && theme[partner] ? theme[partner]?.palette.link : theme.default.palette.main,
  })

  const onSubmit = async (data: any) => {
    setLoading(true)
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: _encodedForm({
        first_name,
        last_name,
        post_code,
        email,
        password: data.password,
        mobile,
        client_id,
        redirect_uri,
      }),
    }
    try {
      const res = await fetch('/api/auth/signup', options)
      const loginData = await res.json()
      if (res.status >= 400) {
        throw loginData
      }

      if (loginData?.username) {
        setLoading(false)
        const sessionData = loginData?.signInUserSession
        Router.push({
          pathname: '/success',
          query: {
            accessToken: loginData?.accessToken || sessionData?.accessToken?.jwtToken,
            refreshToken: loginData?.refreshToken || sessionData?.refreshToken?.token,
            idToken: loginData?.idToken || sessionData?.idToken?.jwtToken,
            pageName: 'signup',
          },
        })
      }
    } catch (error) {
      setError(_parseError(error))
      setLoading(false)
    }
  }

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <Grid className={classes.root} component="main">
      <Head>
        <title>Activate</title>
      </Head>
      <CssBaseline />
      <Header
        title="Activate for free"
        subtitle="Create a Cashrewards account and link with MONEYME Perks to get credit back"
        partner={partner}
        showPartnerText={false}
        alignCenter
      />
      <Grid container style={{ flex: 1 }}>
        <Grid item xs={12} className={classes.formWrapper} style={{ paddingTop: '32px' }}>
          <Grid>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                {/* Start EMAIL field */}
                <Grid item xs={12}>
                  <InputLabel>Email address</InputLabel>
                  <TextField
                    {...inputProps}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                      classes: {
                        notchedOutline: classes.readonlyField,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="email"
                    autoComplete="email"
                    defaultValue={email}
                    helperText={errors.email ? errors.email.message : ''}
                    error={!!errors.email}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                  />
                  {error?.code && ['SE-001', 'SE-002', 'SE-100', 'SE-101'].includes(error?.code) && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </Grid>
                {/* end EMAIL field */}

                {/* start Firstname */}
                <Grid item xs={12} style={{ paddingTop: theme.spacing(errors.email?.message ? 0 : 0.5) }}>
                  <InputLabel>First name</InputLabel>
                  <TextField
                    {...inputProps}
                    variant="outlined"
                    required
                    fullWidth
                    name="first_name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={first_name}
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                      classes: {
                        notchedOutline: classes.readonlyField,
                      },
                    }}
                    id="first_name"
                    helperText={errors.first_name ? errors.first_name.message : ''}
                    error={!!errors.first_name}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                    type="text"
                  />
                </Grid>
                {/* end Firstname */}

                {/* start Lastname */}
                <Grid item xs={12} style={{ paddingTop: theme.spacing(errors.email?.message ? 0 : 0.5) }}>
                  <InputLabel>Last name</InputLabel>
                  <TextField
                    {...inputProps}
                    variant="outlined"
                    required
                    fullWidth
                    name="last_name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                      classes: {
                        notchedOutline: classes.readonlyField,
                      },
                    }}
                    defaultValue={last_name}
                    id="last_name"
                    helperText={errors.last_name ? errors.last_name.message : ''}
                    error={!!errors.last_name}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                    type="text"
                  />
                </Grid>
                {/* end last_name */}

                <Grid item xs={12} style={{ paddingTop: theme.spacing(errors.email?.message ? 0 : 0.5) }}>
                  <InputLabel>Password (min 8 character)</InputLabel>
                  <TextField
                    {...inputProps}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="password"
                    autoComplete="current-password"
                    helperText={errors.password ? errors.password.message : ''}
                    error={!!errors.password}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handlePasswordToggle()} tabIndex="-1" edge="end">
                            {showPassword ? (
                              <img src="/password-eye.svg" alt="passwordShow" />
                            ) : (
                              <img src="/password-eye-slash.svg" alt="passwordHidden" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    type={showPassword ? 'text' : 'password'}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: theme.spacing(0.5) }}>
                  <FormControlLabel
                    {...inputProps}
                    name="acceptTerms"
                    style={{ alignItems: 'end' }}
                    control={<Checkbox disableRipple className={classes.checkbox} />}
                    label={
                      <>
                        <Box component="div" fontSize={11} style={{ lineHeight: '13px' }}>
                          <span>I have read, understood and agree to the </span>
                          <a
                            href="https://www.cashrewards.com.au/terms-and-conditions"
                            className={classes.link}
                            rel="noreferrer"
                          >
                            Terms of Use
                          </a>
                          <span> and </span>
                          <a href="https://www.cashrewards.com.au/privacy" className={classes.link} rel="noreferrer">
                            Privacy Policy
                          </a>
                        </Box>
                        <FormHelperText error={!!errors.acceptTerms} required>
                          {errors.acceptTerms?.message}
                        </FormHelperText>
                      </>
                    }
                  />
                </Grid>
                {error?.code === 'SE-000' && (
                  <>
                    <FormHelperText error className={classes.errorField}>
                      {error?.message}
                    </FormHelperText>
                    <FormHelperText className={classes.infoField}>
                      Please contact
                      <Link href={contactUsUrl}>
                        <a
                          rel="noreferrer"
                          className={classes.link}
                          style={{ paddingLeft: theme.spacing(0.5), paddingRight: theme.spacing(0.5) }}
                        >
                          Cashrewards Members Services Team
                        </a>
                      </Link>
                      for help.
                    </FormHelperText>
                  </>
                )}
                {error?.code && !['SE-001', 'SE-002', 'SE-100', 'SE-101', 'SE-000'].includes(error?.code) && (
                  <>
                    <FormHelperText error className={classes.errorField}>
                      {error?.message}
                    </FormHelperText>
                    <FormHelperText className={classes.infoField}>
                      Please contact
                      <Link href={contactUsUrl}>
                        <a
                          rel="noreferrer"
                          className={classes.link}
                          style={{ paddingLeft: theme.spacing(0.5), paddingRight: theme.spacing(0.5) }}
                        >
                          Cashrewards Members Services Team
                        </a>
                      </Link>
                      for help.
                    </FormHelperText>
                  </>
                )}
              </Grid>
            </form>
          </Grid>
          <Grid item container xs={12} className={classes.buttonWrapper}>
            <Grid item xs={12}>
              <CRButton
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                size="large"
                type="submit"
                loading={loading}
                background={theme[partner]?.palette.green}
                textColor={theme.default.palette.coolblack}
              >
                <span style={{ width: '100%' }}>
                  Sign up
                  <img style={{ float: 'right' }} src={`/arrow-right-icon.svg`} />
                </span>
              </CRButton>
            </Grid>
            {/* <Grid item xs={12} container style={{ textAlign: 'center', paddingTop: theme.spacing(1.5) }}>
                  <Grid item xs={5}>
                    <img src="/hr-line.svg" style={{ width: '100%' }} />
                  </Grid>
                  <Grid item xs={2}>
                    <span style={{ fontSize: theme.spacing(2) }}>or</span>
                  </Grid>
                  <Grid item xs={5}>
                    <img src="/hr-line.svg" style={{ width: '100%' }} />
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: theme.spacing(3) }}>
                  <CRButton
                    variant="contained"
                    size="large"
                    type="button"
                    startIcon="facebook"
                    background={theme.default.palette.facebook}
                    textColor={theme.default.palette.white}
                  >
                    Join with Facebook
                  </CRButton>
                </Grid> */}
          </Grid>
          <Grid
            className={classes.linkWrapper}
            style={{ fontSize: 11, lineHeight: '13px', paddingTop: theme.spacing(1.5), textAlign: 'center' }}
          >
            Already have a Cashrewards account?
            <Link
              as={{
                pathname: `${partner ? `/${partner}` : ''}/login`,
              }}
              href={{
                pathname: `${partner ? '/[partner]' : ''}/login`,
              }}
            >
              <a className={classes.link} style={{ paddingLeft: theme.spacing(0.5) }}>
                Sign in here.
              </a>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
