// core
import Link from 'next/link'

// 3rd party
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { theme } from 'styles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: (props: any) => props.headerBg,
    minHeight: '127px',
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(3)}px`,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#282828',
    fontFamily: 'Lato-extrabold, sans-serif',
  },
  subtitle: {
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#282828',
  },
  imageWrapper: {
    position: 'relative',
    height: '22px',
  },
  partnershipText: {
    fontSize: 8,
    lineHeight: '10px',
  },
  backIcon: {
    position: 'absolute',
    left: 0,
    cursor: 'pointer',
  },
  logo: {
    position: 'absolute',
    left: (props: any) => (props.alignCenter ? '50%' : 0),
    transform: (props: any) => (props.alignCenter ? 'translate(-50%, 0)' : ''),
  },
}))

type HeaderProps = {
  title: string
  subtitle: string
  partner?: string
  showPartnerText?: boolean
  alignCenter?: boolean
  displayBackIcon?: boolean
  backRedirectPath?: string
}

const Header = ({
  title,
  subtitle,
  partner,
  showPartnerText,
  alignCenter,
  displayBackIcon,
  backRedirectPath,
}: HeaderProps): JSX.Element => {
  const _partner = partner && partner.toLowerCase()
  const classes = useStyles({
    headerBg: _partner && theme[_partner] ? theme[_partner]?.palette.headerBg : theme.default.palette.gradient,
    alignCenter,
  })
  return (
    <AppBar elevation={0} className={classes.root} position="static">
      <Toolbar disableGutters style={{ minHeight: 'initial' }}>
        <Grid container>
          <Grid item xs={alignCenter ? 12 : 6} className={classes.imageWrapper}>
            {displayBackIcon ? (
              <Link
                as={{
                  pathname: backRedirectPath,
                }}
                href={{
                  pathname: backRedirectPath,
                }}
              >
                <a>
                  <img src="/back-icon.svg" alt="back" className={classes.backIcon} />
                </a>
              </Link>
            ) : null}

            {_partner === 'mme' ? (
              <img src="/money-me-perks-new.svg" alt="MoneyMe" className={classes.logo} />
            ) : (
              <img src="/cr-logo-word-white.svg" alt="CashRewards" className={classes.logo} />
            )}
          </Grid>
          {showPartnerText ? (
            <Grid item container xs={6} alignItems="center" style={{ textAlign: 'right' }} justify="flex-end">
              <Typography
                variant="h5"
                className={classes.partnershipText}
                align="right"
                style={{ paddingRight: theme.spacing(0.75) }}
              >
                Powered by
              </Typography>
              <img src="/cr-logo-white.png" style={{ height: 12 }} alt="CashRewards" />
            </Grid>
          ) : null}

          <Grid item xs={12} style={{ paddingTop: theme.spacing(2) }}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: `${theme.spacing(0.5)}px ${theme.spacing(5)}px 0` }}>
            <Typography variant="h5" className={classes.subtitle}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
