import React from 'react'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: `${theme.spacing(3)}px 0`,
    textAlign: 'center',
    width: '100%',
    border: '1px solid #E0E0E0',
    maxHeight: theme.spacing(8),
    marginTop: theme.spacing(3),
  },
  footerText: {
    fontSize: 12,
    letterSpacing: '0.01em',
    lineHeight: '130%',
    fontWeight: 'normal',
  },
}))

export default function Copyright() {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.footerText}>
          Copyright © Cashrewards
        </Typography>
      </Grid>
    </Grid>
  )
}
