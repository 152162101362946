// core
import { useState } from 'react'
import Head from 'next/head'

// 3rd party
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { object, string, ref } from 'yup'
import { InputLabel, InputAdornment, IconButton, Typography, FormHelperText } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'

// local
import { CRButton, Header } from 'components'
import { _encodedForm, _parseError, logger, redirect } from 'core'
import styles from './styles'
import useSettings from 'hooks/useSettings'

//model
import { theme } from 'styles'

const MONEY_ME_CLIENT_ID = '1000033'

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  root: {
    [theme.breakpoints.up('xs')]: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '-webkit-fill-available',
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  container: {
    flex: 1,
  },
  formContainerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  fieldSection: {
    paddingBottom: '12px',
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '24px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '50px 0 0',
    },
  },
  title: {
    fontWeight: 800,
    paddingTop: theme.spacing(3),
  },
  successWrapper: {
    paddingTop: theme.spacing(3),
  },
  successHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '130%',
    letterSpacing: '0.01em',
  },
  successTextWrapper: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  successText: {
    fontWeight: 500,
    lineHeight: '130%',
    letterSpacing: '0.01em',
  },
  link: {
    paddingLeft: theme.spacing(0.5),
  },
  successButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}))

interface IError {
  message: string
  name: string
}

type Inputs = {
  password: string
  confirmPassword: string
}

const fixEmail = (email) => {
  if (email && typeof email === 'string') {
    const trimmedEmail = email.trim()
    return trimmedEmail.replace(/ /g, '+')
  }
  return email
}

export default function ResetPassword(): JSX.Element {
  const classes = useStyles()
  const { settings } = useSettings()

  const schema = object().shape({
    password: string().required('Password is required').min(8, 'Your password must be at least 8 characters.'),
    confirmPassword: string().oneOf([ref('password'), null], "Your password didn't match. Please try again."),
  })
  const { register, handleSubmit, errors } = useForm<Inputs>({ resolver: yupResolver(schema) })
  const inputProps: any = { inputRef: register, errors, required: true }
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sessionData, setSessionData] = useState<any>({})

  const { email, cognito_client_id, redirect_uri, token, client_id } = settings
  const fixedEmail = fixEmail(email)

  const partner = client_id === MONEY_ME_CLIENT_ID ? 'mme' : ''

  const [error, setError] = useState<IError>({
    message: '',
    name: '',
  })

  const onSubmit = async ({ password }) => {
    setLoading(true)
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: _encodedForm({
        cognito_client_id,
        email: fixedEmail,
        password,
        redirect_uri,
        token,
      }),
    }

    try {
      const res = await fetch('/api/auth/reset-password', options)
      const loginData = await res.json()
      setSessionData(loginData)
      if (res.status >= 400) {
        throw loginData
      }
      setLoading(false)
      setShowSuccess(true)
    } catch (err) {
      const parsedErr = JSON.parse(err)
      setError(parsedErr)
      setLoading(false)
      setShowSuccess(false)
      logger.error(parsedErr)
    }
  }

  const redirectToShop = () => {
    if (redirect_uri && sessionData) {
      const urlWithTokens = new URL(redirect_uri)
      urlWithTokens.searchParams.append(
        'access_token',
        sessionData?.AccessToken || (sessionData.accessToken?.jwtToken as string)
      )
      urlWithTokens.searchParams.append(
        'refresh_token',
        sessionData?.RefreshToken || (sessionData?.refreshToken?.token as string)
      )
      urlWithTokens.searchParams.append('id_token', sessionData?.IdToken || (sessionData?.idToken?.jwtToken as string))
      redirect(urlWithTokens, true)
    }
  }

  const handlePasswordToggle = (type: string) => {
    type === 'password' ? setShowPassword((prev) => !prev) : setShowConfirmPassword((prev) => !prev)
  }

  return (
    <>
      <Grid className={classes.root}>
        <Head>
          <title>Reset Password</title>
        </Head>
        <CssBaseline />
        <Header
          title={showSuccess ? 'Success!' : 'Set your new password'}
          subtitle={showSuccess ? 'Your password has been reset.' : 'Please set and confirm your Cashrewards Password'}
          partner={partner}
          alignCenter={true}
        />
        <Grid container style={{ flex: 1 }}>
          <Grid item xs={12} className={classes.formWrapper}>
            <Grid className={classes.crLogoWrapper}>
              <img src="/cr-logo-word.svg" alt="Cashrewards" />
            </Grid>
            <Grid style={{ height: '100%' }}>
              {!showSuccess ? (
                <form className={classes.form} noValidate>
                  <Grid className={classes.formContainerWrapper}>
                    <Grid style={{ flex: 1 }} className={classes.container}>
                      <Grid item xs={12} className={classes.fieldSection}>
                        <InputLabel>Set password (min 8 characters)</InputLabel>
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          required
                          fullWidth
                          id="password"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="password"
                          autoComplete="current-password"
                          helperText={errors.password ? errors.password.message : ''}
                          error={!!errors.password}
                          FormHelperTextProps={{ classes: { root: classes.helperText } }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disableRipple
                                  onClick={() => handlePasswordToggle('password')}
                                  tabIndex="-1"
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <img src="/password-eye.svg" alt="passwordShow" />
                                  ) : (
                                    <img src="/password-eye-slash.svg" alt="passwordHidden" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword ? 'text' : 'password'}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.fieldSection}>
                        <InputLabel>Confirm password (min 8 characters)</InputLabel>
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          required
                          fullWidth
                          name="confirmPassword"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          id="confirmPassword"
                          helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                          error={!!errors.confirmPassword}
                          FormHelperTextProps={{ classes: { root: classes.helperText } }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => handlePasswordToggle('confirmPassword')}
                                  tabIndex="-1"
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <img src="/password-eye.svg" alt="passwordShow" />
                                  ) : (
                                    <img src="/password-eye-slash.svg" alt="passwordHidden" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showConfirmPassword ? 'text' : 'password'}
                        />
                        <FormHelperText error>{error?.message}</FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid className={classes.buttonWrapper} onClick={handleSubmit(onSubmit)} style={{ paddingTop: 50 }}>
                      <CRButton variant="contained" color="primary" size="large" type="submit" loading={loading}>
                        Submit
                      </CRButton>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <Grid item xs={12} className={classes.form}>
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center">
                      Please log in to the MoneyMe Perks App using your Cashrewards account.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.successButtonWrapper} style={{ paddingTop: theme.spacing(3) }}>
                    <CRButton
                      variant="contained"
                      size="large"
                      type="submit"
                      background={theme[partner]?.palette.green}
                      onClick={redirectToShop}
                      textColor={theme.default.palette.coolblack}
                    >
                      Open MoneyMe Perks
                    </CRButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
