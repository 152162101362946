// core
import Link from 'next/link'
import { useState } from 'react'
import Router from 'next/router'
import Head from 'next/head'

// 3rd party
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { InputLabel, InputAdornment, IconButton, FormHelperText } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'

// local
import { _encodedForm, logger, _parseError } from 'core'
import { Header, CRButton } from 'components'
import styles from './styles'
import useSettings from 'hooks/useSettings'
import { theme } from 'styles'
import { contactUsUrl } from 'core/constants'

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  form: {
    paddingTop: '24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'underline',
    fontSize: 11,
    lineHeight: '13px',
    zIndex: 10,
    color: (props: any) => props.color,
  },
}))

//model
type Inputs = {
  email: string
  password: string
}

interface IError {
  code?: string
  message: string
  name?: string
}

export default function SignIn() {
  const { settings } = useSettings()
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const { email, client_id, partner, redirect_uri } = settings

  const schema = object().shape({
    email: string().required('Email is required').default(email).email('Please enter a valid email address.'),
    password: string().required('Password is required').min(8, 'Your password must be at least 8 characters.'),
  })
  const { register, handleSubmit, errors } = useForm<Inputs>({ resolver: yupResolver(schema) })

  const [error, setError] = useState<IError>({
    message: '',
    name: '',
  })

  const classes = useStyles({
    color: partner && theme[partner] ? theme[partner]?.palette.link : theme.default.palette.main,
  })

  const inputProps: any = { inputRef: register, errors, required: true }

  const onSubmit = async (data: any) => {
    setLoading(true)
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: _encodedForm({
        client_id: client_id,
        email: data.email,
        password: data.password,
        redirect_uri,
      }),
    }

    try {
      const res = await fetch('/api/auth/signin', options)
      const loginData = await res.json()
      if (res.status >= 400) {
        throw loginData
      }
      if (loginData?.username || loginData?.AccessToken) {
        setLoading(false)
        // Router.push('/[partner]/success', '/success')
        const sessionData = loginData?.signInUserSession
        Router.push({
          pathname: '/success',
          query: {
            accessToken: loginData?.AccessToken || sessionData?.accessToken?.jwtToken,
            refreshToken: loginData?.RefreshToken || sessionData?.refreshToken?.token,
            idToken: loginData?.IdToken || sessionData?.idToken?.jwtToken,
            pageName: 'signin',
          },
        })
      }
    } catch (err) {
      setError(err)
      setLoading(false)
      logger.error(err)
    }
  }

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <Grid className={classes.root}>
      <Head>
        <title>Link to Cashrewards</title>
      </Head>
      <CssBaseline />
      <Header
        title="Link to Cashrewards"
        subtitle="Link your existing Cashrewards account with MONEYME Perks"
        partner={partner}
        alignCenter={true}
      />
      <Grid container style={{ flex: 1 }}>
        <Grid item xs={12} className={classes.formWrapper}>
          <Grid className={classes.crLogoWrapper}>
            <img src="/cr-logo-word.svg" alt="Cashrewards" />
          </Grid>
          <Grid>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel>Email address</InputLabel>
                  <TextField
                    {...inputProps}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    id="email"
                    name="email"
                    defaultValue={email}
                    helperText={errors.email ? errors.email.message : ''}
                    error={!!errors.email}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                  />
                  {error?.name && ['SE-001', 'SE-002', 'SE-100', 'SE-101'].includes(error?.name) && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: theme.spacing(0.5) }}>
                  <InputLabel>Password (min 8 character)</InputLabel>
                  <TextField
                    {...inputProps}
                    variant="outlined"
                    fullWidth
                    name="password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlePasswordToggle} edge="end" tabIndex="-1">
                            {showPassword ? (
                              <img src="/password-eye.svg" alt="passwordShow" />
                            ) : (
                              <img src="/password-eye-slash.svg" alt="passwordHidden" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    helperText={errors.password ? errors.password.message : ''}
                    error={!!errors.password}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: theme.spacing(0.5) }}>
                  <Link
                    as={{
                      pathname: '/forgot-password',
                    }}
                    href={{
                      pathname: '/forgot-password',
                    }}
                  >
                    <a className={classes.link}>Forgot your password?</a>
                  </Link>
                </Grid>
                {error?.message && (
                  <>
                    <FormHelperText error className={classes.errorField}>
                      {error?.message}
                    </FormHelperText>
                    <FormHelperText className={classes.errorField}>
                      Please contact
                      <Link href={contactUsUrl}>
                        <a
                          rel="noreferrer"
                          target={'_blank'}
                          className={classes.link}
                          style={{ paddingLeft: theme.spacing(0.5), paddingRight: theme.spacing(0.5) }}
                        >
                          Cashrewards Members Services Team
                        </a>
                      </Link>
                      for help.
                    </FormHelperText>
                  </>
                )}
              </Grid>
            </form>
          </Grid>
          <Grid item container xs={12} style={{ paddingTop: 0 }}>
            <Grid container className={classes.buttonWrapper}>
              <Grid item xs={12}>
                <CRButton
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  loading={loading}
                >
                  <span style={{ width: '100%' }}>
                    Sign In
                    <img style={{ float: 'right' }} src={`/user-icon.svg`} />
                  </span>
                </CRButton>
              </Grid>
              {/* <Grid item xs={12} container style={{ textAlign: 'center', paddingTop: theme.spacing(1.5) }}>
                <Grid item xs={5}>
                  <img src="/hr-line.svg" style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={2}>
                  <span style={{ fontSize: theme.spacing(2) }}>or</span>
                </Grid>
                <Grid item xs={5}>
                  <img src="/hr-line.svg" style={{ width: '100%' }} />
                </Grid>
              </Grid> */}
              {/* <Grid item xs={12} style={{ paddingTop: theme.spacing(3) }}>
                <CRButton
                  variant="contained"
                  size="large"
                  type="button"
                  startIcon="facebook"
                  background={theme.default.palette.facebook}
                  textColor={theme.default.palette.white}
                >
                  Sign in with Facebook
                </CRButton>
              </Grid> */}
              <Grid
                item
                className={classes.linkWrapper}
                xs={12}
                style={{ fontSize: 11, lineHeight: '13px', paddingTop: theme.spacing(1.5), textAlign: 'center' }}
              >
                No Cashrewards account?
                <Link
                  as={{
                    pathname: `${partner ? `/${partner}` : ''}/register`,
                  }}
                  href={{
                    pathname: `${partner ? '/[partner]' : ''}/register`,
                  }}
                >
                  <a className={classes.link} style={{ paddingLeft: theme.spacing(0.5) }}>
                    Sign up here.
                  </a>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
