// core
import { FC } from 'react'

// 3rd party
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontSize: '14px',
    lineHeight: '17px',
    height: 45,
    color: (props: any) => props.textColor,
    backgroundColor: (props: any) => props.background,
    '&:active': {
      opacity: '0.8',
    },
    '&:focus': {
      opacity: '0.8',
    },
    '&:hover': {
      opacity: '0.8',
      backgroundColor: (props: any) => props.background,
    },
  },
  containedPrimary: {
    '&:hover': {
      opacity: '0.8',
      backgroundColor: theme.default.palette.main,
    },
  },
  startIcon: {
    paddingRight: theme.spacing(2),
  },
}))

type ButtonProps = {
  children: any
  variant: any
  color?: any
  textColor?: string
  size: any
  type: any
  className?: string
  startIcon?: string
  background?: string
  loading?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
}

const CRButton: FC<ButtonProps> = ({
  children,
  variant,
  color,
  size,
  type,
  className,
  textColor,
  startIcon,
  background,
  loading,
  onClick,
}) => {
  const classes = useStyles({ textColor, background })
  return (
    <Button
      variant={variant}
      color={color}
      type={type}
      className={clsx(classes.root, className)}
      size={size}
      disableElevation
      disableRipple
      startIcon={startIcon && <img src={`/${startIcon}-icon.svg`} />}
      classes={{ startIcon: classes.startIcon, containedPrimary: classes.containedPrimary }}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <CircularProgress size={20} /> : children}
    </Button>
  )
}

export default CRButton
