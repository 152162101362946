// core
import React, { Fragment } from 'react'
import Link from 'next/link'
import { useState } from 'react'
import Head from 'next/head'
import ErrorPage from 'next/error'

// 3rd party
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { InputLabel, Typography, FormHelperText } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'

// local
import { CRButton, Header } from 'components'
import { _encodedForm, logger, _parseError } from 'core'
import styles from './styles'
import useSettings from 'hooks/useSettings'
import { contactUsUrl } from 'core/constants'

//model
import { theme } from 'styles'

type Inputs = {
  email: string
  password: string
}
interface IError {
  message: string
  name: string
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '-webkit-fill-available',
  },
  form: {
    paddingTop: '24px',
    paddingBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: 15,
    lineHeight: '140%',
    color: '#282828',
  },
  successWrapper: {
    paddingTop: theme.spacing(3),
  },
  successHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '130%',
    letterSpacing: '0.01em',
  },
  successTextWrapper: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  reminderText: {
    fontSize: '15px',
    lineHeight: '21px',
  },
  successText: {
    fontSize: '15px',
    lineHeight: '18px',
    '&:not(:last-child)': {
      paddingBottom: '1rem',
    },
  },
  link: {
    textDecoration: 'underline',
    lineHeight: '13px',
    color: (props: any) => props.color,
    padding: '0 4px',
  },
}))

export default function ForgotPassword() {
  const { settings } = useSettings()

  const { email, client_id, redirect_uri, partner } = settings || {}

  const classes = useStyles({
    color: partner && theme[partner] ? theme[partner]?.palette.link : theme.default.palette.main,
  })

  const schema = object().shape({
    email: string().required('Email is required').default(email).email('Please enter a valid email address.'),
  })
  const { register, handleSubmit, errors } = useForm<Inputs>({ resolver: yupResolver(schema) })
  const inputProps: any = { inputRef: register, errors, required: true }
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState<IError>({
    message: '',
    name: '',
  })

  if (!settings) {
    return <ErrorPage statusCode={400} />
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: _encodedForm({
        client_id,
        email: data?.email,
        redirect_uri,
      }),
    }
    try {
      const res = await fetch('/api/auth/forgot-password', options)
      const response = await res.json()
      if (res.status >= 400) {
        throw response
      }
      setEmailSent(true)
      setLoading(false)
    } catch (err) {
      const parsedErr = JSON.parse(err)
      setEmailSent(false)
      setError(parsedErr)
      setLoading(false)
      logger.error(parsedErr)
    }
  }

  return (
    <>
      <Grid className={classes.root}>
        <Head>
          <title>Forgot Password</title>
        </Head>
        <CssBaseline />
        <Header
          title="Forgot your password?"
          subtitle="Enter your email address and we will send you a link to reset password."
          partner={partner}
          alignCenter={true}
          displayBackIcon={emailSent ? false : true}
          backRedirectPath="/login"
        />
        <Grid container style={{ flex: 1 }}>
          <Grid item xs={12} className={classes.formWrapper}>
            <Grid className={classes.crLogoWrapper}>
              <img src="/cr-logo-word.svg" alt="Cashrewards" />
            </Grid>
            {!emailSent ? (
              <>
                <Grid>
                  <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel>Email address</InputLabel>
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          id="email"
                          placeholder="Enter email address"
                          name="email"
                          defaultValue={email}
                          helperText={errors.email ? errors.email.message : ''}
                          error={!!errors.email}
                          FormHelperTextProps={{ classes: { root: classes.helperText } }}
                        />
                        <FormHelperText error>{error?.message}</FormHelperText>
                      </Grid>
                    </Grid>
                  </form>
                  <Typography variant="subtitle1" className={classes.reminderText}>
                    <span>If you don't receive an email from Cashrewards within 10 minutes, </span>
                    <Link href={contactUsUrl}>
                      <a
                        rel="noreferrer"
                        className={classes.link}
                        style={{ paddingLeft: theme.spacing(0.5), paddingRight: theme.spacing(0.5) }}
                      >
                        contact us
                      </a>
                    </Link>
                    <span> and we’ll take care of it</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} container className={classes.buttonWrapper} style={{ paddingTop: 0 }}>
                  <CRButton
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Reset password
                  </CRButton>
                </Grid>
              </>
            ) : (
              <Grid container item xs={12} className={classes.successWrapper}>
                <Grid item xs={12} className={classes.successTextWrapper}>
                  <Typography variant="subtitle1" className={classes.successText}>
                    You have been emailed a link to reset your password.
                  </Typography>
                  <Typography variant="subtitle1" className={classes.successText}>
                    Follow the instructions in the email to set a new password that is unique to you.
                  </Typography>
                  <Typography variant="subtitle1" className={classes.successText}>
                    You’ll be saving again in no time!
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
